/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { N20, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { codeFontFamily, borderRadius } from '@atlassian/jira-common-styles/src';
import { getErrorHash } from '../utils/error-hash';

const wrapperStyles = css({
	display: 'inline-block',
	margin: `${token('space.100', '8px')} 0 0`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	background: token('color.background.accent.gray.subtlest', N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily: codeFontFamily,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '0.7em',
	color: token('color.text.subtlest', N500),
});

export const ErrorHash = ({ error }: { error: Error }) => (
	<div data-testid="errors-handling.ui.error-hash">
		<div css={wrapperStyles}>ERROR: {getErrorHash(error)}</div>
	</div>
);
