import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'error-pages.not-found-error-page.header',
		defaultMessage: "We can't find the page you're looking for",
		description: 'Header that tells user we could not find a page with such url',
	},
	description: {
		id: 'error-pages.not-found-error-page.description',
		defaultMessage: `Someone may've deleted it, changed its name, or it's temporarily unavailable.
        {br}
        Check your spelling in the address bar. Or, try opening this site's home page to search or browse for the content.`,
		description: 'Description describing that we could not find a page with such url',
	},
	primaryButton: {
		id: 'error-pages.not-found-error-page.primary-button',
		defaultMessage: 'Go to the homepage',
		description: 'Button sends the user to the homepage',
	},
});
